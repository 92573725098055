@font-face {
  font-family: 'Antenna Regular';
  src: url('assets/fonts/antenna_regular.woff2') format('opentype');
}

* {
  font-family: 'Antenna Regular';
}

@import './app/scss/font.scss';
@import './app/scss/global.scss';