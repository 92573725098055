body {
  margin: 0;
  height: 100vh;
  background: #fafafa;
  font-size: 14px;
}

input,
.p-multiselect,
.p-dropdown,
.p-multiselect {
  height: 2.5rem;
  align-items: center;
  width: -webkit-fill-available;
  font-weight: normal;
  font-size: 12px !important;
}

app-newfile {
  .p-dropdown-filter-container .p-inputtext{
    width: auto;
  }
}
.field > label {
  margin-bottom: 0.25rem;
}

// TODO: REMOVE BELOW CODE
.ford-button button,
button.ford-button.sidebar__button,a.ford-button.sidebar__button {
 background-color: #2d96cd;
 border-color: #2d96cd;
 margin-top: 2.25rem;
}
.ford-button .p-fileupload .p-button {
  margin-top: 0px;
}
.sidebar .sidebar__button{
  padding: 10px!important;
}

.p-button.p-button-text.ford-button {
  color: #2d96cd;
}
.p-button.p-button-text {
  color: #2d96cd;
  position: static;
}
// p-fileupload.ford-button span {
//   background-color: #2d96cd;
// }
p-fileupload .p-fileupload-buttonbar .p-button{
  background-color: #2d96cd;
  &.p-disabled {
    opacity: 0.4;
    opacity: var(--p-disabled-opacity);
  }
}
.p-fileupload-header {
  background: #f8f9fa !important;
  padding: 1rem;
  border: 1px solid #dee2e6;
  color: #495057;
  border-bottom: 0 none;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
}
.p-fileupload-advanced {
  border: 0px;
}
.p-fileupload-content {
  border-top: 1px solid #dee2e6 !important;
  border-left: 0 none;
  border-right: 0 none;
  position: relative;
  .p-progressbar {
    display: none;
  }
}
p {
  //font-family: 'Poppins', sans-serif;
  font-size: 1.1em;
  font-weight: 300;
  line-height: 1.7em;
  color: #999;
}

a,
a:hover,
a:focus {
  color: inherit;
  text-decoration: none;
  transition: all 0.3s;
}

.navbar-btn {
  box-shadow: none;
  outline: none !important;
  border: none;
}

.line {
  width: 100%;
  height: 1px;
  border-bottom: 1px dashed #ddd;
}

.wrapper .content-body {
  box-shadow: 9px 2px 6px 0px rgba(0, 0, 0, 0.1);
}

div.p-datepicker {
  width: 350px;
}
.label-datepicker {
  display: grid;
}

.p-datepicker {
  width: 100%;
}

input.p-column-filter {
  width: 100%;
}

.tableFilter div.p-multiselect {
  width: 100%;
}
.right-side, .p-toolbar-end {
  .p-dropdown {
    width: auto;
  }
  .p-button {
    margin-top: 0px!important;
    padding: 0.5rem 1rem;
  }
}
.p-toolbar-end {
  display: unset !important;
}

.tableFilter div.p-dropdown {
  width: 100%;
  align-items: center;
}

.loading-modal div.p-dialog {
  box-shadow: none;
}

.loading-modal div.p-dialog-content {
  background-color: transparent;
}

.p-dialog-content, .p-dialog-header {
  color: #495057;
}
.container-fluid {
  margin-bottom: 40px;
}

p-fileupload .p-fileupload .p-fileupload-content {
  overflow: auto;
  height: 300px;
}

p-accordion p-fileupload .p-fileupload .p-fileupload-content {
  overflow: auto;
  height: 140px;
}

p-accordion p-fileupload .p-fileupload .p-fileupload-content .p-button {
  height: 31px;
  width: 40px;
}

.load-spinner svg.p-progress-spinner-svg {
  margin-top: 8px !important;
  left: 5px !important;
}

.p-card {
  border: solid 1px #e9ecef;
  box-shadow: none;
}

.p-toolbar {
  border: none;
  border-bottom: solid 1px #efefef;
  background: #f8f9fa !important;
}

.p-datatable {
  .p-datatable-thead {
    th {
      font-weight: 600;
      color: #495057;
      background: #f8f9fa;
      padding: 0.5rem 0.5rem;
      &:is(.p-datepicker-weekday-cell) {
        background-color: #ffffff;
      }
    }
    

    .p-datatable-column-sorted {
      background: #f8f9fa;
      color: #2196F3;
      p-sorticon svg{
        color: #2196f3;
      }
    }
  }
  table {
    border-collapse: collapse;
    table-layout: fixed;
    width: 100%;
    font-weight: normal;
  }
}

.p-calendar .p-inputtext {
  flex: 1 1 auto;
  width: 1%;
}
.p-sortable-column-icon {
  width: 13px;
}
.p-datatable .p-datatable-sortable-column .p-sortable-column-icon {
  color: #6c757d;
  margin-left: 0.5rem;
}
.p-toast {
  .p-toast-message-error {
    // background: #FFCDD2;
    // border: solid #e60017;
    // border-width: 0 0 0 6px;
    // color: #73000c;

    .p-toast-message-icon {
      color: var(--p-toast-error-color);
    }

    .p-toast-close-icon {
      color: var(--p-toast-error-color);
    }
  }
  .p-toast-message-success {
    .p-toast-close-icon {
      color: var(--p-toast-success-color);
    }
  }
  .p-toast-message-warn {
    .p-toast-close-icon {
      color: var(--p-toast-warn-color);
    }
  }
  .p-toast-message-info {
    .p-toast-close-icon {
      color: var(--p-toast-info-color);
    }
  }
}
.p-select:not(.p-disabled):hover, .p-select:not(.p-disabled).p-focus, .p-inputtext:enabled:focus, .p-multiselect:not(.p-disabled).p-focus{
  border-color: #2196F3!important;
  box-shadow: transparent;
  outline: #2196F3!important;
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
  color: #495057!important;
  background-color: #E3F2FD!important;
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-item:not(.p-highlight):not(.p-disabled):hover {
  color: #495057!important;
}
.p-select-option.p-select-option-selected {
  color: #495057!important;
  background: #E3F2FD!important;
}
.p-select-option:not(.p-select-option-selected):not(.p-disabled).p-focus {
  background-color: unset !important;
}
.p-select-option:hover {
  color: #495057;
  background: #e9ecef;
}
.p-paginator-page.p-paginator-page-selected {
  background: #E3F2FD !important;
  border-color: #E3F2FD !important;
  color: #495057 !important;
  border-radius: inherit;
}
.p-inputtext, .p-select, .p-multiselect {
  color: #495057 !important;
  font-size: 12px !important;
  border: 1px solid #ced4da !important;
  font-weight: 400;
  &:disabled {
    opacity: var(--p-disabled-opacity) !important;
    color: black !important;
  }
}
.p-select.p-disabled .p-select-label {
  color: black !important;
}
.p-select.p-disabled, .p-checkbox.p-disabled {
    background: var(--p-inputtext-disabled-background) !important;
    opacity: var(--p-disabled-opacity) !important;
    color: black !important;
}

.p-button.p-button-text:enabled:hover {
  background: rgba(33, 150, 243, 0.04) !important;
  color: #2196F3 !important;
  border-color: transparent !important;
}
.p-checkbox-checked .p-checkbox-box {
  border-color: #2196F3 !important;
  background: #2196F3 !important;
}
.p-button-secondary {
  color: white !important;
}
.p-button:not(:disabled):hover {
  background: #0d89ec !important;
  color: #ffffff !important;
  border-color: #0d89ec !important;
}
.p-button.action.ford-button {
  border-color: #2196F3 !important;
  background: #2196F3 !important;
  padding: 10px;
}
.p-multiselect-overlay {
  position: absolute;
  width: 20rem;
  .p-multiselect-header {
    background: #f8f9fa;
  }
}
.p-dialog .p-dialog-header .p-dialog-header-actions .p-dialog-close-button{
  color: #6c757d !important;
  width: 2rem;
  height: 2rem;
  &:hover {
    color: #495057 !important;
    border-color: transparent !important;
    background: #e9ecef !important;
  }
}
.p-datepicker-dropdown {
  color: #ffffff !important;
  background: #2196F3 !important;
  border: 1px solid #2196F3 !important;
  &:hover {
    background: #0d89ec !important;
    border-color: #0d89ec !important;
  }
}

.p-datepicker-buttonbar {
  .p-button {
    background-color: transparent;
    color: #2196F3;
    border-color: transparent;
  }
}
.p-datepicker-panel {
  table {
    tr {
      background-color: white;
    }
  }
  .p-datepicker-day-selected {
    background: #2196F3 !important;
  }
  .p-datepicker-day-selected-range {
    color: #2196F3;
    background-color: #dff0fd;
  }
  p-footer {
    span {
      color: #495057;
      font-weight: normal;
      font-size: 1rem;
    }
  }
}
.p-panelmenu-submenu {
  padding: 0px !important;
  background-color: #fafafa;
}
.p-panelmenu-panel:has(.p-panelmenu-header-active) {
  margin: 0px !important;
}
.p-button-icon-only.p-button-rounded {
  height: 2.357rem;
  width: 2.357rem;
}
.p-button-icon-only {
  justify-content: center;
}
.p-datatable-tbody > tr > td {
  padding: 0.5rem 0.5rem !important;
}
th .p-select {
  width: -webkit-fill-available;
}
.p-fileupload-file {
  div, .p-fileupload-file-size {
    flex: 1 1 auto;
  }
  .p-fileupload-file-info {
    flex-direction: row !important;
  }
  .p-fileupload-file-actions {
    flex: none;
  }
}
.p-panelmenu-item {
  font-size: 18px;
}
.p-panelmenu-item-content {
  background-color: white;
  border-radius: unset !important;
  color: #495057 !important;
}
.p-accordionpanel:last-child {
  border-top: 1px solid var(--p-accordion-panel-border-color);
}
.area-selector {
  .p-select-label::first-letter {
    text-transform: uppercase;
  }
  .p-select-option span::first-letter {
    text-transform: uppercase;
  }
}
.p-inputtext.ng-invalid.ng-dirty {
  border-color: var(--p-inputtext-invalid-border-color) !important;
}
.p-select {
  //overflow: hidden;
}