
input[type='text']::placeholder,
input[type='number']::placeholder,
input[type='date']::placeholder {
  font-family: 'Antenna Regular';
}

input.p-inputtext {
  font-family: 'Antenna Regular';
  font-size: 14px;
  &::placeholder {
    font-family: 'Antenna Regular';
  }
}

span.p-inputtext,
div.p-multiselect-label {
  font-family: 'Antenna Regular';
  font-size: 14px;
}

div.p-multiselect-label {
  font-size: 12px;
}